/*
 User roles & permissions.
 This structure is based on data received from backend.
 Before changing any keys or values, check with backend
*/
import { convertKeysToCamelCase, createEnum } from '../utils/helpers';

export const PERMISSIONS = convertKeysToCamelCase({
  user: ['create', 'update', 'view', 'view_all', 'deactivate', 'reset_password'],
  role: ['view', 'view_all'],
  customer: ['create', 'update', 'view', 'view_all', 'update_owner', 'refresh_pricing'],
  order: [
    'create',
    'update',
    'cancel',
    'view',
    'view_all',
    'approve',
    'request_term_modification',
    'forward_application',
    'upload_documents',
    'download_documents',
    'download_payoff_statement',
    'request_pay_by_invoice',
    'update_owner',
    'counter_sign_document',
    'download_proposal',
    'request_buyout',
    'approve_purchase_order',
    'download_agreement',
    'upload_sales_quote',
  ],
  payment: ['upload_funding_invoice', 'view', 'view_all'],
  company_setting: ['edit', 'import_customer_csv', 'integration'],
  notification: ['view', 'view_all', 'edit'],
  reseller: ['invite'],
});

// Permissions to show on UI
export const ACTION_TO_TEXT = {
  order: {
    create: 'Create orders',
    cancel: 'Cancel orders',
    update: 'Modify orders',
    forward_application: 'Forward order link and forms',
    upload_documents: 'Upload documents',
    approve: 'Approve orders',
    request_pay_by_invoice: 'Request to pay by invoice',
    send_proposal: 'Send proposal',
    update_owner: 'Edit order assignment',
    counter_sign_document: 'Sign document',
    download_agreement: 'Download agreement',
    upload_sales_quote: 'Upload sales quote'
  },
  customer: {
    add: 'Add customer',
    create: 'Create new customer',
    update: 'Edit customer information',
    update_owner: 'Edit customer assignment',
    refresh_pricing: 'Get new pricing',
  },
  user: {
    create: 'Create new user accounts',
    update: 'Edit user accounts',
    reset_password: 'Reset user passwords',
  },
  payment: {
    upload_funding_invoice: 'Upload funding invoice',
  },
};

const ACTIONS_SET = new Set();
Object.values(PERMISSIONS).forEach((actions) => {
  actions.forEach((action) => ACTIONS_SET.add(action));
});

export const ACTIONS = [...ACTIONS_SET];

export const RESOURCE = createEnum(Object.keys(PERMISSIONS));
export const ACTION = createEnum(ACTIONS, false);
