import {
    Ref,
    useCallback,
    useImperativeHandle,
    useState,
} from 'react';

import { useFormik, FormikProvider } from 'formik';
import { useLocation } from 'react-router-dom';
import { get } from 'lodash';
import { Card, Typography } from '@vartanainc/design-system';
import { useBeforeunload } from 'react-beforeunload';
import { UploadSalesQuote } from '../../../../utils/hooks';
import MultiFileUpload from '../../../../components/MultiFileUpload';
import { salesQuoteSchema } from '../../../../formikSchema/commonSchema';

interface CustomerFormProps {
    customerFormRef: Ref<unknown> | undefined;
    isCrm: boolean;
    creditCheckId: number;
}

const HUBSPOT_FORM_PARAM = '&hubspotCustomerForm=true';

export function SalesQuoteForm({
    customerFormRef,
    isCrm,
    creditCheckId,
}: CustomerFormProps): JSX.Element {
    const location = useLocation();
    const [canSafelyExit, setCanSafelyExit] = useState(true);
    const [attachSalesQuote] = UploadSalesQuote();

    const onSubmit = useCallback(
        async (values) => {
            try {
                const { docs } = values;
                const attachedResource = {
                    id: creditCheckId,
                    type: creditCheckId ? 'CreditEngine::CreditCheck' : 'Company',
                };
                const documentType = {
                    documentType: 'sales_quote',
                };
                if (docs?.length) {
                    if (typeof attachSalesQuote === 'function') {
                        await attachSalesQuote(docs, documentType, attachedResource);
                    }
                }
                const url = location.search.replace(HUBSPOT_FORM_PARAM, '');
                setTimeout(() => {
                    window.open(url, '_self');
                }, 0);
                setCanSafelyExit(true);
            } catch (e) {
                console.error(e);
            }
        },
        [location.search, attachSalesQuote, creditCheckId]
    );

    const formikBag = useFormik({
        initialValues: {},
        enableReinitialize: true,
        validationSchema: salesQuoteSchema,
        onSubmit,
    });

    useImperativeHandle(customerFormRef, () => ({
        formRef: formikBag,
        submitCustomerForm: formikBag.submitForm,
    }));

    useBeforeunload((event) => {
        if (!canSafelyExit) {
            event.preventDefault();
        }
    });

    return (
        <div className={`h-[19.25rem] ${isCrm ? 'customer-form-container' : ''}`}>
            <Card
              padding="compact"
              title={(
                <Typography variant="heading16" color="color-blue-180" bold>
                    Add sales quote to get instant approval
                </Typography>
              )}
              parentContainerClassName="progen-card self-baseline"
              containerClassName="!pb-[1.12rem]"
              content={(
                <FormikProvider value={formikBag}>
                  <div className="flex flex-col gap-1">
                    <MultiFileUpload
                      uploadText="Click or drag PDF files to upload"
                      name="docs"
                      acceptMultipleFiles
                      showError={!!get(formikBag.errors, 'docs')}
                    />
                  </div>
                </FormikProvider>
              )}
            />
        </div>
    );
}